import { fetchData } from './fetchData';
import { html } from './html';

(() => {
  const FeaturedCard = ({ title, link }) => {
    return html`
      <div class="featured-card">
        <div class="wrapper">
          <h2>${title}</h2>
          <a href="${link}"><h4>Learn More</h4></a>
        </div>
      </div>
    `;
  };

  const ArticleCard = ({ img, title, description, link }) => {
    return html`
      <div class="article-card">
        <div class="image-wrapper" style="background-image: url(${img})"></div>
        <div class="text-container">
          <h3>${title}</h3>
          <small> ${description} </small>
          <a href="${link}"><h4>Read Article</h4></a>
        </div>
      </div>
    `;
  };

  const FeaturedCardImage = ({ title, img, link }) => {
    return html`
      <div class="featured-card-image">
        <div class="wrapper">
          <h2>${title}</h2>
          <img src=${img} />
          <a href="${link}"><h4>Learn More</h4></a>
        </div>
      </div>
    `;
  };

  const ExternalCard = ({ img, title, link, buttonText }) => {
    return html`
      <div class="external-card">
        <div class="image-wrapper" style="background-image: url(${img})"></div>
        <div class="text-wrapper">
          <h3>${title}</h3>
          <h4><a href=${link} class="external">${buttonText}</a></h4>
        </div>
      </div>
    `;
  };
  const init = async () => {
    const cards = await fetchData('/data/homeCards.json');
    const div = document.querySelector('div#masonry');

    const cardTypes = cards.map(card => {
      if (card.type === 'featured-card') {
        return FeaturedCard(card);
      } else if (card.type === 'article-card') {
        return ArticleCard(card);
      } else if (card.type === 'featured-card-image') {
        return FeaturedCardImage(card);
      } else if (card.type === 'external-card') {
        return ExternalCard(card);
      }
    });
    div.innerHTML = cardTypes.join('');
  };
  document.addEventListener('DOMContentLoaded', init);
})();
